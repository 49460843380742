import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  inject,
  input,
  signal,
} from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { DropdownSelectUi } from "./dropdown-select.ui";

// TODO: fix bug host injection

@Component({
  standalone: true,
  selector: "dropdown-select-option-ui",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    "[class.active]": "checked",
    "[class.hidden]": "!show()",
    "(click)": "toggle()",
  },
  imports: [MatCheckboxModule, MatRadioModule],
  template: `
    <div class="roam-option-content">
      <ng-content />
    </div>
    @if (isMulti) {
      <mat-checkbox [value]="value()" [checked]="checked" />
    } @else {
      <mat-radio-button [checked]="checked" [value]="value()" />
    }
  `,
})
export class DropdownSelectOptionUi {
  protected parent = inject(DropdownSelectUi, { host: true, optional: true });
  readonly value = input<any>(null);
  readonly show = signal<boolean>(true);
  readonly searchIndex = input<string>("");

  get isMulti() {
    return this.parent?.multiple();
  }

  get checked() {
    return this.parent?.selected().includes(this.value());
  }

  toggle() {
    if (this.parent?.multiple()) {
      if (this.checked) {
        this.parent.selected.update(items => {
          return items.filter(x => x !== this.value());
        });
      } else {
        this.parent.selected.update(items => {
          return [...items, this.value()];
        });
      }
    } else {
      this.parent?.selected.set([this.value()]);
      this.parent?.opened.set(false);
    }
  }
}
