<div class="position-relative">
  <swiper #swiper [config]="options">
    @for (item of data(); track $index) {
      <ng-template swiperSlide class="swiper-card">
        <div class="card-wrapper">
          <ui-metric-card
            [title]="item.name"
            [accountId]="item.id"
            [number]="item.number"
            [change]="item.change"
            [isCurrency]="isCurrency()" />
        </div>
      </ng-template>
    }
  </swiper>
  <button
    class="btn-swiper-nav prev"
    (click)="prev()"
    [ngStyle]="{ visibility: isBeginning ? 'hidden' : 'visible' }"
    [disabled]="isBeginning">
    <span [inlineSVG]="icon.arrowNext.src"></span>
  </button>
  <button
    class="btn-swiper-nav next"
    (click)="next()"
    [ngStyle]="{ visibility: isEnd ? 'hidden' : 'visible' }"
    [disabled]="isEnd">
    <span [inlineSVG]="icon.arrowNext.src"></span>
  </button>
</div>
