<div cdkOverlayOrigin class="select-trigger" #trigger="cdkOverlayOrigin">
  @if (appearance() === "input") {
    <mat-form-field
      (click)="opened.set(!opened())"
      cdkOverlayOrigin
      appearance="outline"
      class="fake-input-dropdown-wrapper white-bg label-field w-100 input-dropdown"
      floatLabel="auto">
      <input matInput [placeholder]="label()" readonly />
      <div class="chevron-icon">
        <roam-icon name="chevron-down" size="12" color="base" />
      </div>
    </mat-form-field>
  }

  @if (appearance() === "button") {
    <button
      (click)="opened.set(!opened())"
      [class.has-badge]="!!totalSelected()"
      [class.opened]="opened()"
      class="select-trigger-button"
      mat-stroked-button
      type="button">
      <div class="select-trigger-wrapper">
        <ng-template
          [ngTemplateOutlet]="labelTemplate() || defaultLabel"></ng-template>
        @if (!!totalSelected()) {
          <span class="select-trigger-badge">{{ totalSelected() }}</span>
        }
        <mat-icon class="expand-icon" matSuffixText
          >keyboard_arrow_down</mat-icon
        >
      </div>
    </button>
  }
</div>

<ng-template
  cdkConnectedOverlay
  (backdropClick)="opened.set(false)"
  [cdkConnectedOverlayOffsetY]="10"
  [cdkConnectedOverlayHasBackdrop]="true"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayPanelClass]="overlayPanelClass()"
  cdkConnectedOverlayMinWidth="22rem"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="opened()">
  @if (showSearch()) {
    <div class="search-wrapper">
      <div class="search-form-field">
        <span>🔎</span>
        <input placeholder="Search" type="search" [formControl]="searchForm" />
      </div>
    </div>
  }
  <div class="panel-body">
    <ng-content />

    @if (multiple()) {
      <div (click)="toggleAll()" class="roam-option-select-all">
        <span>Select All</span>
        <mat-checkbox [checked]="allSelected" />
      </div>
    }

    <ng-content select="dropdown-select-option-ui" />
  </div>
  @if (multiple()) {
    <div class="panel-footer">
      <a (click)="clear()" class="clear-button">Clear filters</a>
      <button
        (click)="opened.set(false)"
        class="submit-button"
        mat-flat-button
        type="button">
        Search filters
      </button>
    </div>
  }
</ng-template>

<ng-template #defaultLabel>{{ label() }}</ng-template>
