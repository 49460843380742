import { CurrencyPipe, NgStyle } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  input,
  viewChild,
} from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { InlineSVGModule } from "ng-inline-svg-2";
import { SwiperOptions } from "swiper";
import { SwiperComponent, SwiperModule } from "swiper/angular";
import { MetricCardUi } from "./metric-card.ui";

export interface MetricSlideItem {
  id: string;
  name: string;
  number: number;
  change: number;
}

const defaultOptions: SwiperOptions = {
  slidesPerView: 4,
  spaceBetween: 20,
  breakpoints: {
    1370: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    1250: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    1000: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 2,
    },
  },
};

@Component({
  standalone: true,
  selector: "ui-metrics-slider",
  templateUrl: "metrics-slider.ui.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host ::ng-deep.swiper {
        padding: 5px !important;
      }
    `,
  ],
  imports: [NgStyle, SwiperModule, InlineSVGModule, MetricCardUi, CurrencyPipe],
})
export class MetricsSliderUi {
  readonly data = input.required<MetricSlideItem[]>();
  readonly isCurrency = input(true);
  readonly swiper = viewChild(SwiperComponent);
  protected options = defaultOptions;

  get icon() {
    return iconLib;
  }

  get swiperRef() {
    return this.swiper()?.swiperRef;
  }

  get isBeginning(): boolean {
    return this.swiperRef?.isBeginning || false;
  }

  get isEnd(): boolean {
    return this.swiperRef?.isEnd || false;
  }

  next(): void {
    this.swiperRef?.slideNext(100);
  }

  prev(): void {
    this.swiperRef?.slidePrev(100);
  }
}
