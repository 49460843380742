import { CurrencyPipe, NgStyle } from "@angular/common";
import { Component, input } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { RouterModule } from "@angular/router";

@Component({
  standalone: true,
  selector: "ui-metric-card",
  styleUrl: "./metric-card.ui.scss",
  templateUrl: "./metric-card-.ui.html",
  imports: [MatCardModule, RouterModule, NgStyle, CurrencyPipe],
})
export class MetricCardUi {
  readonly accountId = input<string>();
  readonly title = input.required<string>();
  readonly number = input.required<number>();
  readonly change = input.required<number>();
  readonly duration = input<number>();
  readonly isCurrency = input(false);

  get direction(): string {
    return this.change() > 0 ?
        "/assets/svg/ic-up.svg"
      : "/assets/svg/ic-down.svg";
  }

  get colorChange(): string {
    return this.change() > 0 ? "#2ab51e" : "#d40101";
  }
}
