<mat-card appearance="outlined" class="summary w-100 card-metric">
  <mat-card-content class="summary__widget">
    <p class="summary__widget-title">
      <a
        class="summary__widget-title"
        [routerLink]="[
          '/reporting/report-detail/account-register/',
          accountId()
        ]">
        {{ title() }}
      </a>
    </p>
    <p class="summary__widget-price">
      {{ number() || 0 }}
    </p>
    <p class="summary__widget-total align-center">
      <img [src]="direction" alt="arrow Icon" class="c-pointer" />
      <span [ngStyle]="{ color: colorChange }">
        {{
          isCurrency() ?
            (change() | currency: "USD" : "symbol" : "1.0-2")
          : change()
        }}
      </span>
      <span>{{ duration() || "this month" }}</span>
    </p>
  </mat-card-content>
</mat-card>
