import { OverlayModule } from "@angular/cdk/overlay";
import { NgTemplateOutlet } from "@angular/common";
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  contentChildren,
  DestroyRef,
  inject,
  input,
  model,
  signal,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { DropdownSelectOptionUi } from "./dropdown-select-option.ui";

type Value = string | number | boolean | null;

@Component({
  standalone: true,
  selector: "dropdown-select-ui",
  styleUrl: "./dropdown-select.ui.scss",
  templateUrl: "./dropdown-select.ui.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    OverlayModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    NgTemplateOutlet,
    ReactiveFormsModule,
    DropdownSelectOptionUi,
    RoamIconComponent,
  ],
})
export class DropdownSelectUi implements AfterViewInit {
  #destroyRef = inject(DestroyRef);
  readonly opened = signal(false);
  readonly multiple = input(false, { transform: booleanAttribute });
  readonly showSearch = input(false, { transform: booleanAttribute });
  readonly label = input<string>(""); // label altenative
  readonly appearance = input<"button" | "input">("button");
  readonly panelClass = input<string[], string | string[]>([], {
    transform: (v: string | string[]) => {
      if (Array.isArray(v)) return v;
      else return [v];
    },
  });
  protected overlayPanelClass = computed(() => [
    "roam-option-overlay-panel",
    ...this.panelClass(),
  ]);
  readonly selected = model<Value[]>([]);
  readonly totalSelected = computed(() => this.selected().length);
  readonly options = contentChildren(DropdownSelectOptionUi);
  readonly labelTemplate = contentChild("labelTemplate", { read: TemplateRef });

  readonly searchForm = new FormControl("");

  get allSelected() {
    return this.options().every(x => this.selected().includes(x.value()));
  }

  toggleAll(): void {
    if (!this.options().every(x => this.selected().includes(x.value()))) {
      const values = this.options().map(x => x.value());
      this.selected.set(values);
    } else {
      this.selected.set([]);
    }
  }

  clear(): void {
    this.selected.set([]);
    this.opened.set(false);
  }

  ngAfterViewInit(): void {
    this.searchForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe(keyword => {
        this.options().forEach(opt => {
          const show = Boolean(
            opt
              .searchIndex()
              .toLowerCase()
              .includes(keyword || "")
          );
          opt.show.set(show);
        });
      });
  }
}
